<div class="dialog-wrapper">
  <h1 mat-dialog-title>{{ title }}</h1>

  <mat-dialog-content>
    <form [formGroup]="form">
      <div class="flex flex-col">
        <mat-form-field appearance="outline" class="w-full">
          <mat-label>Name</mat-label>
          <input matInput [formControl]="form.controls.name" required>
          <mat-error class="mt-5" *ngIf="form.controls.name.hasError('required')">Name is required</mat-error>
          <mat-error class="mt-5" *ngIf="form.controls.name.hasError('validationErrors')">
            {{ form.controls.name.getError('validationErrors') }}
          </mat-error>
        </mat-form-field>

        <div class="my-8">
          <app-dropzone-upload [accept]="accept" (filesChanged)="documentFileChangedHandler($event)" (click)="form.controls.template.markAsTouched()"></app-dropzone-upload>
          <p class="mat-error text-sm pl-3 my-2" *ngIf="form.controls.template.touched && form.controls.template.hasError('required')">Template is required</p>
        </div>
      </div>
    </form>
  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <button mat-button type="button" (click)="onCancel()">Cancel</button>

    <button mat-raised-button color="primary" (click)="onSubmit()" [disabled]="form.invalid">Save</button>
  </mat-dialog-actions>
</div>

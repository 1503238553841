<ng-container *ngTemplateOutlet="(canAcceptPayment$ | async) ? paymentForm : cantProcessPayments">
</ng-container>

<ng-template #paymentForm>
  <ng-container *ngrxLet="loading$ as loading">
    <ng-container *ngrxLet="invalid$ as invalid">
      <ng-container *ngIf="paymentMethods$ | async as paymentMethods">
        <form (ngSubmit)="makePayment()"
              class="stripe-payment-wrapper"
              id="payment-form">
          <ng-container
            *ngTemplateOutlet="paymentMethods.length ? existingPaymentMethod : newPaymentMethod; context: {paymentMethods}">
          </ng-container>

          <!-- Errors container      -->
          <ng-container *ngrxLet="selectedBillingAddress$ as selectedBillingAddress">
            <div *ngIf="!selectedBillingAddress">
              <mat-error class="block text-sub my-4">
                You need to choose your billing address before making a payment
              </mat-error>
            </div>
          </ng-container>

          <button [disabled]="invalid"
                  id="submit">
            <div [class.hidden]="!loading"
                 class="spinner hidden"
                 id="spinner"></div>
            <span [class.hidden]="loading"
                  id="button-text">Pay now
            </span>
          </button>
        </form>
      </ng-container>
    </ng-container>
  </ng-container>
</ng-template>

<ng-template #newPaymentMethod>
  <div [id]="linkAuthenticationElementId">
    <!--Stripe.js injects the Link Authentication Element-->
  </div>
  <div [id]="paymentElementId">
    <!--Stripe.js injects the Payment Element-->
  </div>
</ng-template>

<ng-template #existingPaymentMethod
             let-paymentMethods="paymentMethods">
  <section class="list-cards-wrapper mb-3">
    <p class="mat-subheading-1">Choose card to make payment</p>
    <mat-radio-group [(ngModel)]="selectedPaymentMethod"
                     aria-label="Select a payment method"
                     class="group-selection-outline w-full">
      <div class="list-cards-container flex flex-col w-full">
        <mat-radio-button (change)="selectPaymentMethod(paymentMethod)"
                          *ngFor="let paymentMethod of paymentMethods"
                          [value]="paymentMethod"
                          class="select-option !w-full">
          <div class="flex flex-row items-center">
            <img alt="card-type"
                 class="w-9 mr-4"
                 height="48"
                 ngSrc="assets/images/logo/{{paymentMethod.cardBrand}}.svg"
                 width="56">
            <p class="!mb-0">
              <span
                class="!mat-subheading-1 !mb-0 font-['OpenSans_Semibold'] !font-semibold !text-sub capitalize">{{ paymentMethod.cardBrand }}</span>&nbsp;
              <span
                class="!font-primary !text-sub">&bullet;&bullet;&bullet;&nbsp;{{ paymentMethod.cardLastFour }} </span>
            </p>
          </div>
        </mat-radio-button>
      </div>
    </mat-radio-group>
  </section>
  <div class="w-full inline-flex justify-end mb-4">
    <button (click)="addNewPaymentMethod()"
            class="!text-sub"
            color="accent"
            mat-button
            type="button">
      <mat-icon>add_card</mat-icon>
      add new card
    </button>
  </div>
</ng-template>

<ng-template #cantProcessPayments>
  <app-callout message="Coming Soon!"></app-callout>
</ng-template>

<ng-template #addCardDialog>
  <div class="add-card-wrapper min-w-[36rem]">
    <h1 class="mb-0 !text-[2.2rem] text-darkGray text-center"
        mat-dialog-title>Add Card</h1>
    <mat-dialog-content>
      <app-stripe-setup-form (setupSuccess)="onSetupSuccess($event)"></app-stripe-setup-form>
    </mat-dialog-content>
  </div>
</ng-template>

import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@Component({
  selector: 'app-table-loading',
  standalone: true,
  templateUrl: './table-loading.component.html',
  styleUrls: ['./table-loading.component.scss'],
  imports: [CommonModule, MatProgressSpinnerModule],
})
export class TableLoadingComponent implements OnInit {
  constructor() { }

  ngOnInit(): void { }
}

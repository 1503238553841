import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators} from '@angular/forms';
import {MatDialogRef, MAT_DIALOG_DATA, MatDialogModule} from '@angular/material/dialog';
import {MatInputModule} from "@angular/material/input";
import {MatButtonModule} from "@angular/material/button";
import {CommonModule} from "@angular/common";
import {
  DropzoneAction, DropzoneFileChangedEvent,
  DropzoneUploadComponent
} from "@shared/component/common/dropzone-upload/dropzone-upload.component";
import {ScreenSpinnerService} from "@shared/component/full-screen-spinner/screen-spinner.service";
import {DocumentTemplateApi} from "@modules/document-template/api/document-template.api";
import {CreateDocumentTemplateInput, UpdateDocumentTemplateInput} from "@generated/graphql";
import {catchError, tap} from 'rxjs';
import {SnackBarService} from "@shared/services/snack-bar.service";
import {ErrorService} from "@shared/services/error.service";

export type DocumentTemplateForm = FormGroup<{
  id: FormControl<string>
  name: FormControl<string>
  template: FormControl<any>
  hasDocumentTemplateId: FormControl<string>
  hasDocumentTemplateType: FormControl<string>
}>

export type DocumentTemplateFormComponentData = {
  id?: string,
  name?: string,
  hasDocumentTemplateId: '',
  hasDocumentTemplateType: ''
}

@Component({
  standalone: true,
  selector: 'app-document-template-form',
  templateUrl: './document-template-form.component.html',
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatInputModule,
    MatButtonModule,
    MatDialogModule,
    DropzoneUploadComponent
  ],
  styleUrls: ['./document-template-form.component.scss']
})
export class DocumentTemplateFormComponent implements OnInit {
  static width = '50rem'
  form: DocumentTemplateForm;

  isUpdate: boolean;
  accept = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'

  get title() {
    return this.isUpdate ? 'Update Document Template' : 'Create Document Template'
  }

  constructor(
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<DocumentTemplateFormComponent>,
    private screenSpinnerService: ScreenSpinnerService,
    private documentTemplateApi: DocumentTemplateApi,
    private snackBarService: SnackBarService,
    private errorService: ErrorService,
    @Inject(MAT_DIALOG_DATA) public data: DocumentTemplateFormComponentData
  ) {
  }

  ngOnInit(): void {
    this.isUpdate = !!this.data?.id;

    this.form = this.fb.group({
      id: [undefined, this.isUpdate ? [Validators.required] : []],
      name: [undefined, this.getRequiredValidator()],
      template: [undefined, this.getRequiredValidator()],
      hasDocumentTemplateId: [undefined, this.getRequiredValidator()],
      hasDocumentTemplateType: [undefined, this.getRequiredValidator()],
    }) as DocumentTemplateForm;

    this.form.patchValue(this.data, { onlySelf: true, emitEvent: false })
  }

  private getRequiredValidator() {
    return this.isUpdate ? [] : [Validators.required];
  }

  onSubmit(): void {
    if (this.form.valid) {
      this.screenSpinnerService.loading()

      if (!this.isUpdate) {
        const createInput: CreateDocumentTemplateInput = {
          name: this.form.value.name,
          template: this.form.value.template,
          hasDocumentTemplateId: this.form.value.hasDocumentTemplateId,
          hasDocumentTemplateType: this.form.value.hasDocumentTemplateType
        }

        this.documentTemplateApi
          .create$(createInput)
          .pipe(
            tap(data => {
              this.screenSpinnerService.stopLoading()

              this.snackBarService.pushSuccessAlert('Template created successfully!')

              this.dialogRef.close(data)
            }),
            catchError(({graphQLErrors}) => {
              this.screenSpinnerService.stopLoading()

              this.errorService.getValidationErrorObjectList(graphQLErrors).showErrors(this.form);

              this.snackBarService.pushErrorMessage();

              return graphQLErrors
            })
          )
          .subscribe()

        return;
      }

      const updateInput: UpdateDocumentTemplateInput = {
        id: this.form.value.id,
        name: this.form.value.name,
        template: this.form.value.template,
        hasDocumentTemplateId: this.form.value.hasDocumentTemplateId,
        hasDocumentTemplateType: this.form.value.hasDocumentTemplateType
      }

      this.documentTemplateApi
        .update$(updateInput)
        .pipe(
          tap(data => {
            this.screenSpinnerService.stopLoading()

            this.snackBarService.pushSuccessAlert('Template updated successfully!')

            this.dialogRef.close(data)
          }),
          catchError(({graphQLErrors}) => {
            this.screenSpinnerService.stopLoading()

            this.errorService.getValidationErrorObjectList(graphQLErrors).showErrors(this.form);

            this.snackBarService.pushErrorMessage();

            return graphQLErrors
          })
        )
        .subscribe()
    }
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  documentFileChangedHandler({files, type}: DropzoneFileChangedEvent) {
    if (type === DropzoneAction.Added) {
      this.form.controls.template.setValue(files[0]);
    } else {
      this.form.controls.template.setValue(null);
    }
  }
}

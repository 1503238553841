import {BaseModel} from "@shared/models/base.model";
import {DocumentTemplateType, HasDocumentTemplateType} from "@generated/graphql";
import {User} from "@modules/user/models/user.model";
import {File} from "@modules/file/models/file.model";
import {userFactory} from "@modules/user/factories/user.factory";
import {ServiceSupportedCountry} from "@modules/service/models/service-supported-country.model";

export const placeValue = (value) => {
  return value ? value : '_______'
}

export const applyPlaceValue = (obj: any): any => {
  if (obj === null || obj === undefined) return placeValue('');

  if (typeof obj === 'object' && !Array.isArray(obj)) {

    return Object.fromEntries(
      Object.entries(obj).map(([key, value]) => [key, applyPlaceValue(value)])
    );
  }

  return placeValue(obj);
}

export const addAlphabetPrefix = (arr: string[]): string[] => {
  return arr.map((item, index) => `(${String.fromCharCode(97 + index)}) ${item}`);
}

export type HasDocumentTemplate = ServiceSupportedCountry

export class DocumentTemplate extends BaseModel implements DocumentTemplateType {
  id: string;
  name: string;
  template: File;
  createdBy: User;
  _model: string;
  hasDocumentTemplate: HasDocumentTemplate

  constructor(state: DocumentTemplateType) {
    super(state);

    this.createdBy = userFactory.tryCreating(state.createdBy)
  }

  getModelName(): string {
    return DocumentTemplate.name;
  }
}

import {Component, Inject, Input, OnInit} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatTableModule} from '@angular/material/table';
import {MatPaginatorModule} from '@angular/material/paginator';
import {FormBuilder, ReactiveFormsModule} from '@angular/forms';
import {MatInputModule} from '@angular/material/input';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from "@angular/material/icon";
import {TableLoadingComponent} from "@shared/component/common/table/table-loading/table-loading.component";
import {NoRecordComponent} from "@shared/component/common/table/no-record/no-record.component";
import {BehaviorSubject, catchError, tap} from "rxjs";
import {MatDialog} from "@angular/material/dialog";
import {
  DocumentTemplateFormComponent, DocumentTemplateFormComponentData
} from "@modules/document-template/components/document-template-form/document-template-form.component";
import FileService from "@modules/file/services/file.service";
import {DocumentTemplate} from "@modules/document-template/models/document-template.model";
import {ShareDirectivesModule} from "@shared/share-directives/share-directives.module";
import {DocumentTemplateApi} from "@modules/document-template/api/document-template.api";
import {SnackBarService} from "@shared/services/snack-bar.service";
import {ScreenSpinnerService} from "@shared/component/full-screen-spinner/screen-spinner.service";
import {clone} from "lodash-es";
import {LetModule} from "@ngrx/component";
import {
  ViewTemplateComponent,
  ViewTemplateComponentData
} from "@modules/document-template/components/view-template/view-template.component";
import {MatTooltipModule} from "@angular/material/tooltip";
import {Router} from "@angular/router";
import {APP_ROUTES, AppRoutes} from "@config/app-routes.config";
import {
  DocumentGeneratorService
} from "@modules/document-template/components/document-generator/document-generator.service";


@Component({
  selector: 'app-document-template-table[data]',
  standalone: true,
  imports: [CommonModule, MatTableModule, MatPaginatorModule, ReactiveFormsModule, MatInputModule, MatButtonModule, MatIconModule, TableLoadingComponent, NoRecordComponent, ShareDirectivesModule, LetModule, MatTooltipModule],
  templateUrl: './document-template-table.component.html',
  styleUrls: ['./document-template-table.component.scss'],
})
export class DocumentTemplateTableComponent implements OnInit {
  @Input() data: {
    templateData: Record<string, any>,
    hasDocumentTemplateId: string,
    hasDocumentTemplateType: string
  }

  @Input() documentTemplates: DocumentTemplate[]

  displayedColumns: string[] = ['name', 'createdBy', 'createdAt', 'actions'];
  _dataSource$ = new BehaviorSubject<DocumentTemplate[]>([]);
  _loading$ = new BehaviorSubject(false);

  get dataSource$() {
    return this._dataSource$.asObservable()
  }

  readonly loading$ = this._loading$.asObservable()

  constructor(
    private fb: FormBuilder,
    private matDialog: MatDialog,
    private fileService: FileService,
    private documentTemplateApi: DocumentTemplateApi,
    private snackBarService: SnackBarService,
    private screenSpinnerService: ScreenSpinnerService,
    private router: Router,
    @Inject(APP_ROUTES) public appRoutes: AppRoutes,
    private readonly documentGeneratorService: DocumentGeneratorService
  ) {
  }

  create() {
    const {hasDocumentTemplateId, hasDocumentTemplateType} = this.data

    const ref = this.matDialog.open<
      DocumentTemplateFormComponent,
      DocumentTemplateFormComponentData,
      DocumentTemplate | null
    >(DocumentTemplateFormComponent, {
      width: DocumentTemplateFormComponent.width,
      data: {
        hasDocumentTemplateId,
        hasDocumentTemplateType
      } as DocumentTemplateFormComponentData
    })

    ref.afterClosed().pipe(
      tap(res => this._dataSource$.next([res, ...this._dataSource$.value].filter(v => !!v)))
    ).subscribe()
  }

  update(document: DocumentTemplate) {
    const {hasDocumentTemplateId, hasDocumentTemplateType} = this.data

    const ref = this.matDialog.open<
      DocumentTemplateFormComponent,
      DocumentTemplateFormComponentData,
      DocumentTemplate | null
    >(DocumentTemplateFormComponent, {
      width: DocumentTemplateFormComponent.width,
      data: {
        id: document.id,
        name: document.name,
        hasDocumentTemplateId,
        hasDocumentTemplateType
      } as DocumentTemplateFormComponentData
    })

    ref.afterClosed().pipe(
      tap(updatedDocument => {
        if (updatedDocument) {
          const newData = clone(this._dataSource$.value)

          const index = newData.findIndex(i => i.id === updatedDocument.id)

          if (index > -1) {
            console.log(index)

            newData[index] = updatedDocument
          }

          this._dataSource$.next(newData)
        }
      })
    ).subscribe()
  }

  downloadFile(id: string): void {
    this.fileService.downloadFile(id)
  }

  ngOnInit(): void {
    this._dataSource$.next([...this.documentTemplates])
  }

  delete(element: DocumentTemplate) {
    this.screenSpinnerService.loading()

    this.documentTemplateApi.delete$(element.id).pipe(
      tap(res => {
        this.screenSpinnerService.stopLoading()

        if (res) {
          this.snackBarService.pushItemDeletedMessage()

          this.removeItem(element)

          return
        }

        this.snackBarService.pushErrorMessage()
      }),
      catchError(e => {
        this.screenSpinnerService.stopLoading()

        this.snackBarService.pushErrorMessage()

        return e
      })
    ).subscribe()
  }

  view(template: DocumentTemplate) {
    this.screenSpinnerService.loading()

    return this.fileService.downloadFile$(template.template.id)
      .pipe(
        tap(url => {
          this.matDialog.open<ViewTemplateComponent, ViewTemplateComponentData>(ViewTemplateComponent, {
            ...ViewTemplateComponent.config,
            data: {
              url,
              template,
            }
          })

          this.screenSpinnerService.stopLoading()
        }),
        catchError(e => {
          this.screenSpinnerService.stopLoading()

          this.snackBarService.pushErrorMessage()

          return e
        })
      ).subscribe()
  }

  download(item: DocumentTemplate) {
    return this.downloadFile(item.template.id)
  }

  removeItem(item: DocumentTemplate) {
    const values = clone(this._dataSource$.value)

    const index = values.findIndex(i => i.id === item.id)

    if (index > -1) {
      values.splice(index, 1)

      this._dataSource$.next([...values])
    }
  }

  compile(template: DocumentTemplate) {
    this.documentGeneratorService
      .setTemplate(template)
      .setData(this.data.templateData)

    this.router.navigate(this.appRoutes.documentTemplateGenerator())
  }
}

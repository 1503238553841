import {Injectable} from "@angular/core";
import {
  CreateDocumentTemplateInput,
  CreateDocumentTemplateMutation,
  DeleteDocumentTemplateMutation,
  DocumentTemplateType, UpdateDocumentTemplateInput, UpdateDocumentTemplateMutation
} from "@generated/graphql";
import {map} from "rxjs";
import {documentTemplateFactory} from "@modules/document-template/factories/document-template.factory";

@Injectable({
  providedIn: 'root'
})
export class DocumentTemplateApi {
  constructor(
    private readonly createDocumentTemplateMutation: CreateDocumentTemplateMutation,
    private readonly deleteDocumentTemplateMutation: DeleteDocumentTemplateMutation,
    private readonly updateDocumentTemplateMutation: UpdateDocumentTemplateMutation,
  ) {
  }


  create$(input: CreateDocumentTemplateInput) {
    return this.createDocumentTemplateMutation.mutate({input}, {
      context: {
        useMultipart: true,
      },
    }).pipe(
      map(res => res.data.createDocumentTemplate
        ? documentTemplateFactory.create(res.data.createDocumentTemplate as DocumentTemplateType)
        : null
      )
    )
  }

  update$(input: UpdateDocumentTemplateInput) {
    return this.updateDocumentTemplateMutation.mutate({input}, {
      context: {
        useMultipart: true,
      },
    }).pipe(
      map(res => res.data.updateDocumentTemplate
        ? documentTemplateFactory.create(res.data.updateDocumentTemplate as DocumentTemplateType)
        : null
      )
    )
  }

  delete$ (id: string) {
    return this.deleteDocumentTemplateMutation.mutate({id}).pipe(
      map(res => res.data.deleteDocumentTemplate)
    )
  }
}

<div
  *ngIf="orderSummary$ | async as orderSummary"
  class="order-summary-wrapper"
>
  <ng-container *ngrxLet="businessPlan$ as businessPlan">

    <!-- Table Header -->
    <section class="inline-flex w-full">
      <div class="flex-grow basis-2/5 w-2/5">
        <span class="mat-title !text-sub !mb-0 text-darkGray">Item</span>
      </div>
      <div
        *ngIf="businessPlan.isPremium()"
        class="flex-grow basis-1/5 w-1/5 text-center"
      >
        <span class="mat-title !text-sub !mb-0 text-darkGray"
        >Rate ({{ orderSummary.currency }})</span
        >
      </div>
      <div class="flex-grow basis-1/5 w-1/5 text-center">
        <span class="mat-title !text-sub !mb-0 text-darkGray">Quantity</span>
      </div>
      <div
        *ngIf="businessPlan.isPremium()"
        class="flex-grow basis-1/5 w-1/5 text-center"
      >
        <span class="mat-title !text-sub !mb-0 text-darkGray"
        >Amount ({{ orderSummary.currency }})</span
        >
      </div>
    </section>

    <mat-divider class="!my-4"></mat-divider>

    <!-- Main Items -->
    <section class="services-container">
      <div
        *ngFor="let item of orderSummary.items"
        class="service-item inline-flex w-full mb-2"
      >
        <div class="flex-grow basis-2/5 w-2/5">
          <p class="text-sub !mb-0">
            <span>{{ item.name }}</span>
            <span
              *ngIf="
                businessPlan.isPremium() && item.paymentType?.isSubscription
              "
              class="italic text-darkGray opacity-80"
            >&nbsp;(billed
              {{ item.paymentType.cycleLabel.toLocaleLowerCase() }})</span
            >
          </p>
        </div>
        <div
          *ngIf="businessPlan.isPremium()"
          class="flex-grow basis-1/5 w-1/5 text-center"
        >
          <span
            *ngIf="item.paymentType?.isContactToClient; else showServiceFee"
            class="!text-sub !mb-0"
          >-</span
          >
          <ng-template #showServiceFee>
            <span class="!text-sub !mb-0">{{ item.rate | number }}</span>
          </ng-template>
        </div>
        <div class="flex-grow basis-1/5 w-1/5 text-center">
          <span class="!text-sub !mb-0">{{ item.quantity }}</span>
        </div>
        <div
          *ngIf="businessPlan.isPremium()"
          class="flex-grow basis-1/5 w-1/5 text-center"
        >
          <span
            *ngIf="item.paymentType?.isContactToClient"
            class="!text-sub !mb-0"
          >You'll be contacted</span
          >
          <span
            *ngIf="!item.paymentType?.isContactToClient"
            class="!text-sub !mb-0 break-words"
          >
            {{ item.amount | number }}
          </span>
        </div>
      </div>

      <!-- Divider to separate main items from summary rows -->
      <mat-divider class="!my-4"></mat-divider>

      <!-- Summary Rows in the Same Table -->

      <ng-container *ngIf="businessPlan.isPremium()">
        <!-- Subtotal Row -->
        <div class="service-item inline-flex w-full mb-2">
          <div class="flex-grow basis-2/5 w-2/5">
            <p class="!text-sub !mb-0 mat-title">Subtotal</p>
          </div>
          <div class="flex-grow basis-1/5 w-1/5 text-center"></div>
          <div class="flex-grow basis-1/5 w-1/5 text-center"></div>
          <div class="flex-grow basis-1/5 w-1/5 text-center">
            <span class="!text-sub !mb-0 break-words">
              {{
                orderSummary.subTotalAmount
                  | currency
                  : orderSummary.currency
                    : "symbol"
                    : (orderSummary.subTotalAmount % 1 === 0 ? "1.0-0" : "1.2-2")
              }}
            </span>
          </div>
        </div>

        <!-- Promotion Discount Row (moved below Subtotal) -->
        <ng-container *ngIf="appliedPromotion | async as promotion">
          <div class="service-item inline-flex w-full mb-2">
            <div class="flex-grow basis-2/5 w-2/5">
              <p class="!text-sub !mb-0 mat-title">Promotion Discount</p>
            </div>
            <div class="flex-grow basis-1/5 w-1/5 text-center"></div>
            <div class="flex-grow basis-1/5 w-1/5 text-center"></div>
            <div class="flex-grow basis-1/5 w-1/5 text-center">
              <span class="!text-sub !mb-0 break-words">
                <!-- If discount amount is provided from server -->
                <ng-container *ngIf="order?.discountAmount; else calculateDiscount">
                  - {{ order.discountAmount | currency: orderSummary.currency }}
                </ng-container>
                <!-- Otherwise calculate discount based on subtotal -->
                <ng-template #calculateDiscount>
                  - {{
                    (orderSummary.subTotalAmount * promotion.discount / 100) |
                      currency: orderSummary.currency: 'symbol' :
                      ((orderSummary.subTotalAmount * promotion.discount / 100) % 1 === 0 ? '1.0-0' : '1.2-2')
                  }}
                </ng-template>
              </span>
            </div>
          </div>
        </ng-container>

        <!-- GST/VAT Row -->
        <div
          *ngIf="orderSummary.supportedCountry.includeTax"
          class="service-item inline-flex w-full mb-2"
        >
          <div class="flex-grow basis-2/5 w-2/5">
            <p class="!text-sub !mb-0 mat-title">
              GST/VAT {{ orderSummary.supportedCountry.taxRate }}%
            </p>
          </div>
          <div class="flex-grow basis-1/5 w-1/5 text-center"></div>
          <div class="flex-grow basis-1/5 w-1/5 text-center"></div>
          <div class="flex-grow basis-1/5 w-1/5 text-center">
            <span class="!text-sub !mb-0 break-words">
              {{
                orderSummary.taxAmount && orderSummary.taxAmount !== 0
                  ? (orderSummary.taxAmount
                    | currency
                    : orderSummary.currency
                      : "symbol"
                      : (orderSummary.taxAmount % 1 === 0 ? "1.0-0" : "1.2-2"))
                  : "N/A"
              }}
            </span>
          </div>
        </div>

        <!-- Official Fees Row -->
        <div class="service-item inline-flex w-full mb-2">
          <div class="flex-grow basis-2/5 w-2/5">
            <p class="!text-sub !mb-0 mat-title">Official fees</p>
          </div>
          <div class="flex-grow basis-1/5 w-1/5 text-center"></div>
          <div class="flex-grow basis-1/5 w-1/5 text-center"></div>
          <div class="flex-grow basis-1/5 w-1/5 text-center">
            <span class="!text-sub !mb-0 break-words">
              {{
                orderSummary.officialFee && orderSummary.officialFee !== 0
                  ? (orderSummary.officialFee
                    | currency
                    : orderSummary.currency
                      : "symbol"
                      : (orderSummary.officialFee % 1 === 0 ? "1.0-0" : "1.2-2"))
                  : "N/A"
              }}
            </span>
          </div>
        </div>

        <mat-divider class="!my-4"></mat-divider>

        <!-- Total Row -->
        <div class="service-item inline-flex w-full mb-2">
          <div class="flex-grow basis-2/5 w-2/5">
            <p class="!text-sub !mb-0 mat-title">Total</p>
          </div>
          <div class="flex-grow basis-1/5 w-1/5 text-center"></div>
          <div class="flex-grow basis-1/5 w-1/5 text-center"></div>
          <div class="flex-grow basis-1/5 w-1/5 text-center">
            <span class="!text-sub !mb-0 break-words mat-title">
              <ng-container *ngIf="appliedPromotion | async as promotion; else totalWithoutPromo">
                {{
                  (order?.discountAmount
                      ? order?.totalAmount
                      : orderSummary.totalAmount - (orderSummary.subTotalAmount * promotion.discount / 100)
                  )
                    | currency: orderSummary.currency : 'symbol' : (
                    (order?.discountAmount
                        ? order?.totalAmount
                        : orderSummary.totalAmount - (orderSummary.subTotalAmount * promotion.discount / 100)
                    ) % 1 === 0 ? '1.0-0' : '1.2-2'
                  )
                }}
              </ng-container>
              <ng-template #totalWithoutPromo>
                {{
                  order?.totalAmount
                    | currency: orderSummary.currency : 'symbol' : (order?.totalAmount % 1 === 0 ? '1.0-0' : '1.2-2')
                }}
              </ng-template>
            </span>
          </div>
        </div>

        <!-- Equal to USD Row -->
        <div *ngIf="orderSummary.currency !== 'USD'" class="service-item inline-flex w-full mb-2">
          <div class="flex-grow basis-2/5 w-2/5"></div>
          <div class="flex-grow basis-1/5 w-1/5 text-center"></div>
          <div class="flex-grow basis-1/5 w-1/5 text-center"></div>
          <div class="flex-grow basis-1/5 w-1/5 text-center">
            <span class="!text-sub !mb-0 break-words !font-medium mat-title">
              <ng-container
                *ngIf="order?.payment?.amount && !order.payment.isAttemptingPayment else calculateExchangedTotal">
                ( Equal to {{
                  order.payment.amount
                    | currency: 'USD': 'code': (order.payment.amount % 1 === 0 ? '1.0-0' : '1.2-2')
                }} )
              </ng-container>
              <ng-template #calculateExchangedTotal>
                <ng-container *ngIf="currencyExchangeRate">
                  <ng-container *ngIf="appliedPromotion | async as promotion; else exchangedTotalWithoutPromo">
                    ( Equal to {{
                      ((orderSummary.totalAmount - (orderSummary.subTotalAmount * promotion.discount / 100)) * currencyExchangeRate)
                        | currency: 'USD': 'code': ((((orderSummary.totalAmount - (orderSummary.subTotalAmount * promotion.discount / 100)) * currencyExchangeRate) % 1 === 0) ? '1.0-0' : '1.2-2')
                    }} )
                  </ng-container>
                  <ng-template #exchangedTotalWithoutPromo>
                    ( Equal to {{
                      (orderSummary.totalAmount * currencyExchangeRate)
                        | currency: 'USD': 'code': ((orderSummary.totalAmount * currencyExchangeRate) % 1 === 0 ? '1.0-0' : '1.2-2')
                    }} )
                  </ng-template>
                </ng-container>
              </ng-template>
            </span>
          </div>
        </div>
      </ng-container>
    </section>

    <!-- Promo Code Section -->
    <section *ngIf="businessPlan.isPremium() && shouldShowPromoCode" class="flex items-center gap-2 min-h-[80px]">
      <!-- Promo Icon -->
      <svg fill="none" height="18" viewBox="0 0 18 18" width="18" xmlns="http://www.w3.org/2000/svg">
        <path clip-rule="evenodd"
              d="M14.25 6H13.845C14.1094 5.54424 14.2491 5.02689 14.25 4.5C14.25 3.25736 13.2426 2.25 12 2.25C10.7829 2.39897 9.68941 3.06602 9 4.08C8.31059 3.06602 7.21706 2.39897 6 2.25C4.75736 2.25 3.75 3.25736 3.75 4.5C3.75093 5.02689 3.89062 5.54424 4.155 6H3.75C2.92157 6 2.25 6.67157 2.25 7.5V9.75C2.25 10.1642 2.58579 10.5 3 10.5H3.75V14.25C3.75 15.0784 4.42157 15.75 5.25 15.75H12.75C13.5784 15.75 14.25 15.0784 14.25 14.25V10.5H15C15.4142 10.5 15.75 10.1642 15.75 9.75V7.5C15.75 6.67157 15.0784 6 14.25 6ZM12 3.75C12.4142 3.75 12.75 4.08579 12.75 4.5C12.7508 4.99894 12.5034 5.46559 12.09 5.745C11.8417 5.91186 11.5492 6.00067 11.25 6H9.75C9.75 5.1825 11.1825 3.75 12 3.75ZM6 3.75C5.58579 3.75 5.25 4.08579 5.25 4.5C5.25 5.32843 5.92157 6 6.75 6H8.25C8.25 5.1825 6.8175 3.75 6 3.75ZM8.25 7.5V9H3.75V7.5H8.25ZM8.25 14.25V10.5H5.25V14.25H8.25ZM9.75 14.25V10.5H12.75V14.25H9.75ZM9.75 7.5V9H14.25V7.5H9.75Z"
              fill="#00A3FF"
              fill-rule="evenodd"/>
      </svg>

      <!-- Promo Code Link -->
      <span
        (click)="showPromoCode()"
        (keydown.enter)="showPromoCode()"
        (keydown.space)="showPromoCode()"
        class="text-[#00A3FF] cursor-pointer"
      >
        I have a promo code
      </span>


      <!-- Promo Code Input & Action Buttons -->
      <ng-container *ngIf="promoCodeVisible" class="flex items-center gap-2">
        <form (ngSubmit)="applyPromoCode()" [formGroup]="promoCodeForm">
          <mat-form-field appearance="outline" class="w-60 ml-6">
            <mat-label>Input code here</mat-label>
            <input
              #promoCodeInput
              (keydown.enter)="applyPromoCode(); $event.preventDefault()"
              formControlName="promoCode"
              matInput
              placeholder="Input code here"
              type="text"
            >
            <button
              (click)="promoCodeForm.controls.promoCode.setValue('')"
              *ngIf="promoCodeForm.controls.promoCode.value"
              aria-label="Clear"
              mat-icon-button
              matSuffix
              type="button"
            >
              <mat-icon>highlight_off</mat-icon>
            </button>
          </mat-form-field>
        </form>
        <button (click)="applyPromoCode()" class="!ml-2" color="primary" mat-flat-button type="submit">
          Apply
        </button>

        <!-- Show Remove button if promo code is valid -->
        <ng-container *ngIf="promotionApplicationCheckResult?.isValid">
          <button (click)="removePromoCode()" class="!ml-2" color="warn" mat-flat-button>Remove</button>
        </ng-container>

        <ng-container *ngIf="isCheckingPromo; else validationMessages">
          <div class="ml-4 inline-flex text-[#00A3FF]">
            <mat-icon class="spin">autorenew</mat-icon>
            <span class="ml-1 inline-block">Checking promo code...</span>
          </div>
        </ng-container>

        <ng-template #validationMessages>
          <ng-container
            *ngIf="promotionApplicationCheckResult?.type && !promotionApplicationCheckResult?.isValid">
            <div class="ml-4 inline-flex text-[#F44336]">
              <mat-icon>error_outline</mat-icon>
              <span class="ml-1 inline-block">{{ promotionApplicationCheckResult?.message }}</span>
            </div>
          </ng-container>

          <ng-container *ngIf="promotionApplicationCheckResult?.isValid">
            <div class="ml-4 inline-flex text-[#19C20A]">
              <mat-icon>check_circle_outline</mat-icon>
              <span class="ml-1 inline-block">{{ promotionApplicationCheckResult?.message }}</span>
            </div>
          </ng-container>
        </ng-template>
      </ng-container>

    </section>
  </ng-container>
</div>

import {Injectable} from '@angular/core';
import {map, Observable} from "rxjs";
import {CurrencyExchangeRateQuery} from "@generated/graphql";

@Injectable({
  providedIn: 'root'
})
export class CurrencyService {

  constructor(
    private readonly currencyExchangRateQuery: CurrencyExchangeRateQuery
  ) {
  }

  getCurrencyExchangeRate(fromCurrency: string, toCurrency?: string): Observable<number> {
    return this.currencyExchangRateQuery.fetch({fromCurrency, toCurrency}, {fetchPolicy: 'network-only'}).pipe(
      map(res => res.data.currencyExchangeRate)
    )
  }
}

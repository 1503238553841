import {Injectable} from "@angular/core";
import {DocumentTemplate} from "@modules/document-template/models/document-template.model";
import {BehaviorSubject} from "rxjs";

@Injectable({providedIn: 'root'})
export class DocumentGeneratorService {
  private readonly _template$ = new BehaviorSubject<DocumentTemplate>(null)
  private readonly _data$ = new BehaviorSubject<Record<string, any>>(null)


  get data() {
    return this._data$.value
  }

  get template() {
    return this._template$.value
  }

  get template$() {
    return this._template$.asObservable()
  }

  get data$() {
    return this._data$.asObservable()
  }

  setTemplate(template: DocumentTemplate): this {
    this._template$.next(template)

    return this
  }

  setData(data: Record<string, any>) {
    this._data$.next(data)

    return this
  }
}

import {MatDialog} from '@angular/material/dialog';
import {CommonModule} from '@angular/common';
import {MatCardModule} from '@angular/material/card';
import {MatDividerModule} from '@angular/material/divider';
import {MatIconModule} from '@angular/material/icon';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatButtonModule} from '@angular/material/button';
import {BillingAddress} from '@modules/address/models/billing-address.model';
import {
  AddressRemovalComponent,
  RemoveAddressDialogData,
} from '@modules/address/components/dialog/address-removal/address-removal.component';
import {
  UpdateAddressDialogComponent,
  UpdateAddressDialogData,
} from '@modules/address/components/dialog/update-address-dialog/update-address-dialog.component';
import {BehaviorSubject, catchError, first, switchMap, tap} from 'rxjs';
import {BillingAddressApi} from '@modules/address/api/billing-address.api';
import {AuthService} from '@modules/auth/services/auth.service';
import {SnackBarService} from '@shared/services/snack-bar.service';
import {UpdateBillingAddressInput} from '@generated/graphql';
import {FormBuilder, FormGroup} from '@angular/forms';
import {BillingAddressService} from '@modules/address/services/billing-address.service';
import {cloneDeep} from "lodash-es";
// import {
//   BillingManagementPageComponent,
//   BillingManagementPageComponentData
// } from "@modules/account-settings/common/pages/billing-management-page/billing-management-page.component";
import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CommonHelper} from "@shared/helpers/common.helper";
import {SnackBarTypeEnum} from "@shared/types/commonEnum";

export type BillingAddressComponentConfig = {
  showDefaultBadged: boolean
  showTitle: boolean
  showActions: boolean
  showEditAction: boolean
  showDeleteAction: boolean
  showManageAction: boolean
  manageLabel: string
}

export const billingAddressComponentConfig: BillingAddressComponentConfig = {
  showDefaultBadged: true,
  showActions: false,
  showEditAction: true,
  showDeleteAction: false,
  showTitle: false,
  showManageAction: false,
  manageLabel: 'Manage'
};

@Component({
  standalone: true,
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatButtonModule,
    MatIconModule,
    MatCardModule,
    MatDividerModule,
  ],
  selector: 'app-billing-address[address]',
  templateUrl: './billing-address.component.html',
  styleUrls: ['./billing-address.component.scss'],
})
export class BillingAddressComponent implements OnInit {
  @Output() manage: EventEmitter<void> = new EventEmitter<void>()
  @Input() address: BillingAddress;
  @Input() config: BillingAddressComponentConfig = cloneDeep(billingAddressComponentConfig);

  form: FormGroup;

  constructor(
    private readonly dialog: MatDialog,
    private readonly fb: FormBuilder,
    private readonly billingAddressApi: BillingAddressApi,
    private readonly billingAddressService: BillingAddressService,
    private readonly authService: AuthService,
    private readonly snackBarService: SnackBarService,
    public readonly commonHelper: CommonHelper,
  ) {
  }

  private _loading$ = new BehaviorSubject<boolean>(false);

  get loading$() {
    return this._loading$.asObservable();
  }

  ngOnInit(): void {
  }

  setAsDefault(value: BillingAddress): void {
    try {
      this.form = UpdateAddressDialogComponent.initializeControl(this.fb, value);

      this.form.patchValue({...value, isDefault: true});

      this.snackBarService.dismiss();

      if (this.form.valid) {
        this._loading$.next(true);

        this.billingAddressApi
          .update$(this.form.value as UpdateBillingAddressInput)
          .pipe(
            tap(address => {
              this.snackBarService.pushAlert('Your default address has been updated.', '', SnackBarTypeEnum.success);
            }),
            switchMap(() => this.billingAddressService.refreshMyBillingAddresses$()),
            catchError(e => {
              this.snackBarService.pushAlert('Oops, something went wrong. Please try setting the default address again', '', SnackBarTypeEnum.error);

              return e;
            }),
          )
          .subscribe();
      }

    } catch (e) {
      console.error('Something wrong cant set address as default');
    }
  }

  onUpdate(address: BillingAddress): void {
    const addressDialog = this.dialog.open<
      UpdateAddressDialogComponent,
      UpdateAddressDialogData,
      BillingAddress
    >(UpdateAddressDialogComponent, {
      width: '60rem',
      data: {
        address,
      } as UpdateAddressDialogData,
    });

    addressDialog
      .afterClosed()
      .pipe(
        first(),
        tap(address => {
          if (address) {
            const updatedAddress = cloneDeep(address);

            this.billingAddressService.dispatchUpsertEntityAction(updatedAddress)
          }
        })
      ).subscribe()
  }

  onRemove(address: BillingAddress): void {
    const removalDialog = this.dialog.open<
      AddressRemovalComponent,
      RemoveAddressDialogData,
      boolean
    >(AddressRemovalComponent, {
      width: '60rem',
      data: {
        formType: 'DELETE',
        address,
      } as RemoveAddressDialogData,
    });

    removalDialog
      .afterClosed()
      .pipe(
        first(),
        tap(isDelete => {
          if (isDelete) {
            this.billingAddressService.dispatchDeleteEntityAction(address.id)
          }
        })
      )
      .subscribe()
  }

  onManage() {
    this.manage.emit()

  }
}

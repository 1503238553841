import {
  EntityEntry,
  EntityFeatureName,
  EntityFeatureNameEnum,
  EntityStore,
  EntityStoreEnum,
  SelectState
} from "@shared/contracts/entity-store.contract";
import {PromotionType} from "@generated/graphql";
import {Promotion} from "@modules/promotion/models/promotion.model";
import {InjectionToken, Provider} from "@angular/core";

export class PromotionStore extends EntityStore<EntityFeatureNameEnum.Promotion, PromotionType, Promotion> {
  get selectState(): SelectState<PromotionType> {
    return this.feature.selectPromotionFeatureState;
  }

  getEntry(): EntityEntry<EntityFeatureNameEnum.Promotion> {
    return 'PROMOTION';
  }

  getFeatureName(): EntityFeatureName<EntityFeatureNameEnum.Promotion> {
    return 'promotionFeature';
  }
}

export const promotionStore = new PromotionStore()

export const PROMOTION_STORE = new InjectionToken<EntityStoreEnum>(EntityStoreEnum.PromotionStore)

export const PROMOTION_STORE_CONFIG: Provider = {
  provide: PROMOTION_STORE,
  useValue: promotionStore,
}

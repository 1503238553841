<div class="dialog-wrapper">
  <h2 mat-dialog-title class="truncate">Template: {{ data.template.name }}</h2>
  <mat-dialog-content class="dialog-content">
    <app-document-template-viewer class="w-full" [url]="data.url"></app-document-template-viewer>
  </mat-dialog-content>

  <mat-dialog-actions class="dialog-actions" align="end">
    <button mat-raised-button type="button" (click)="close()">Close</button>
  </mat-dialog-actions>
</div>

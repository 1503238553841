import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CommonModule} from "@angular/common";
import {NgxDocViewerModule} from "ngx-doc-viewer";
import {BehaviorSubject, map} from "rxjs";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";

@Component({
  standalone: true,
  imports: [
    CommonModule,
    NgxDocViewerModule,
    MatProgressSpinnerModule
  ],
  selector: 'app-document-template-viewer[url]',
  templateUrl: './document-template-viewer.component.html',
  styleUrls: ['./document-template-viewer.component.scss']
})
export class DocumentTemplateViewerComponent implements OnInit {
  @Input() url: string;
  @Output() documentLoaded = new EventEmitter<boolean>()

  _loaded$ = new BehaviorSubject<boolean>(false)

  get loaded$ () {
    return this._loaded$.asObservable()
  }

  get loading$ () {
    return this.loaded$.pipe(
      map(loaded => !loaded)
    )
  }

  constructor() {
  }

  ngOnInit(): void {
  }

  loadEnded() {
    this._loaded$.next(true)

    this.documentLoaded.next(true)
  }

  loadStart() {
    this._loaded$.next(false)

    this.documentLoaded.next(false)
  }
}

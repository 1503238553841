import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogModule, MatDialogRef} from "@angular/material/dialog";
import {MatButtonModule} from "@angular/material/button";
import {CommonModule} from "@angular/common";
import {NgxDocViewerModule} from "ngx-doc-viewer";
import {ScreenSpinnerService} from "@shared/component/full-screen-spinner/screen-spinner.service";
import {DocumentTemplate} from "@modules/document-template/models/document-template.model";
import {
  DocumentTemplateViewerComponent
} from "@modules/document-template/components/document-template-viewer/document-template-viewer.component";

export type ViewTemplateComponentData = {
  template: DocumentTemplate
  url: string
}

@Component({
  standalone: true,
  selector: 'app-view-template',
  templateUrl: './view-template.component.html',
  imports: [
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    NgxDocViewerModule,
    DocumentTemplateViewerComponent
  ],
  styleUrls: ['./view-template.component.scss']
})
export class ViewTemplateComponent implements OnInit {
  static readonly config = {
    maxWidth: 'none',
    width: '80vw',
    height: '80vh'
  }

  constructor(
    private dialogRef: MatDialogRef<ViewTemplateComponent>,
    @Inject(MAT_DIALOG_DATA) public readonly data: ViewTemplateComponentData,
  ) {
  }

  ngOnInit(): void {
  }

  close() {
    this.dialogRef.close()
  }

  loadEnded() {
  }
}

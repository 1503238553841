import {Component, ElementRef, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {EMPTY, finalize, Observable, switchMap, tap} from 'rxjs';
import {take} from 'rxjs/operators';
import {OrderSummary} from '@shared/component/payments/components/order-summary/order-summary.type';
import {OrderSummaryService} from '@shared/component/payments/components/order-summary/order-summary.service';
import {CommonModule} from '@angular/common';
import {MatDividerModule} from '@angular/material/divider';
import {BusinessPlanService} from '@app/modules/account-settings/pages/business-profile/services/business-plan.service';
import {LetModule} from '@ngrx/component';
import {CurrencyService} from '@modules/payment/services/currency.service';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {FormBuilder, FormsModule, ReactiveFormsModule} from '@angular/forms';
import {PromotionService} from '@modules/promotion/services/promotion.service';
import {Promotion} from '@modules/promotion/models/promotion.model';
import {PromotionApplicationCheckResultType, PromotionType} from '@generated/graphql';
import {SnackBarService} from '@shared/services/snack-bar.service';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {Order} from "@modules/order/models/order.model";

@UntilDestroy()
@Component({
  selector: 'app-order-summary',
  standalone: true,
  templateUrl: './order-summary.component.html',
  styleUrls: ['./order-summary.component.scss'],
  imports: [
    CommonModule,
    MatDividerModule,
    LetModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatButtonModule,
    FormsModule,
    ReactiveFormsModule
  ],
})
export class OrderSummaryComponent implements OnInit, OnDestroy {
  currencyExchangeRate: number;
  promoCodeVisible = false;
  promoCodeForm = this.fb.group({
    promoCode: [''],
  });
  isCheckingPromo = false;
  @ViewChild('promoCodeInput') promoCodeInput: ElementRef;
  @Input() shouldShowPromoCode: boolean;
  appliedPromotion: Observable<Promotion> = this.promotionService.activeEntity$;
  order: Order;
  promotionApplicationCheckResult: PromotionApplicationCheckResultType;

  constructor(
    private readonly orderSummaryService: OrderSummaryService,
    private readonly _businessPlanService: BusinessPlanService,
    private readonly currencyService: CurrencyService,
    private readonly fb: FormBuilder,
    private readonly promotionService: PromotionService,
    private readonly snackbarService: SnackBarService,
  ) {
  }

  get orderSummary$(): Observable<OrderSummary> {
    return this.orderSummaryService.orderSummary$;
  }

  get businessPlan$() {
    return this._businessPlanService.businessPlan$;
  }

  ngOnInit(): void {
    this.orderSummary$.pipe(
      tap(orderSummary => {
        this.order = orderSummary.data.order;
        const promotion = this.order?.promotion;

        if (promotion) {
          this.promotionService.dispatchAddOrUpdateThenSelectEntityAction(promotion);
        }
      }),
      switchMap(orderSummary =>
        this.currencyService.getCurrencyExchangeRate(orderSummary.currency).pipe(
          tap(rate => this.currencyExchangeRate = rate),
        )
      ),
      untilDestroyed(this)
    ).subscribe();
  }

  ngOnDestroy(): void {
    // Remove any active promotion on destroy, if exists.
    this.appliedPromotion.pipe(take(1)).subscribe(promo => {
      if (promo?.id) {
        this.promotionService.dispatchDeleteEntityAction(promo.id);
      }
    });
  }

  showPromoCode(): void {
    this.promoCodeVisible = true;
    setTimeout(() => this.promoCodeInput.nativeElement.focus());
  }

  applyPromoCode(): void {
    const promoCodeValue = this.promoCodeForm.get('promoCode')?.value;

    if (!promoCodeValue) {
      return;
    }

    this.appliedPromotion.pipe(
      take(1),
      tap(activePromo => {
        if (activePromo?.id) {
          // Clear the previously applied promotion first
          this.promotionService.dispatchDeleteEntityAction(activePromo.id);
        }
      }),
      switchMap(() => {
        // Start the new promo code check after clearing the previous one
        this.isCheckingPromo = true;

        return this.promotionService.checkCanApplyPromotionByCode(promoCodeValue, this.order.id);
      }),
      tap((checkResult: PromotionApplicationCheckResultType) => {
        // Store the BE validation result for display purposes
        this.promotionApplicationCheckResult = checkResult;
      }),
      switchMap((checkResult: PromotionApplicationCheckResultType) => {
        if (checkResult.isValid) {
          return this.promotionService.findByCode(promoCodeValue);
        }
        // If the check fails, exit the chain without error
        return EMPTY;
      }),
      tap((promotion: PromotionType) => {
        this.promotionService.dispatchAddOrUpdateThenSelectEntityAction(promotion);
      }),
      finalize(() => this.isCheckingPromo = false),
      untilDestroyed(this)
    ).subscribe({
      error: () => {
        this.snackbarService.pushErrorAlert('Failed to apply promo code');
      }
    });
  }

  removePromoCode(): void {
    this.appliedPromotion.pipe(take(1)).subscribe(promo => {
      if (promo?.id) {
        this.promotionService.dispatchDeleteEntityAction(promo.id);
        this.promoCodeForm.get('promoCode')?.setValue('');
        this.promotionApplicationCheckResult = null;
      }
    });
  }
}

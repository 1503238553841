import {Component, Input, OnInit} from '@angular/core';
import {CommonModule} from "@angular/common";
import {MatCardModule} from "@angular/material/card";

@Component({
  selector: 'app-info-section-wp',
  standalone: true,
  templateUrl: './section-wp.component.html',
  styleUrls: ['./section-wp.component.scss'],
  imports: [CommonModule, MatCardModule]
})
export class SectionWpComponent implements OnInit {
  private _title: string = '';
  private _caption: string = '';

  constructor() {
  }

  @Input()
  get sectionTitle(): string {
    return this._title;
  }

  set sectionTitle(value: string) {
    this._title = value;
  }

  @Input()
  get sectionCaption(): string {
    return this._caption;
  }

  set sectionCaption(value: string) {
    this._caption = value;
  }

  ngOnInit(): void {
  }

}

<div *ngIf="job$ | async as job" class="job-scope-and-fee-wrapper">
  <ng-container *ngrxLet="businessPlan$ as businessPlan">
    <mat-card class="!px-8 !pt-8">
      <mat-card-content>
        <!--      SCOPE OF WORK-->
        <section *ngIf="job.scopeOfWork" class="scope-container mb-6">
          <p class="!mb-2 mat-title text-darkGray !text-base">Scope of work</p>
          <mat-card class="mat-elevation-z0">
            <mat-card-content [innerHTML]="job.scopeOfWork" class="text-sub">
            </mat-card-content>
          </mat-card>
        </section>

        <!--      JOB FEE-->
        <section *ngIf="orderSummary$ | async" class="fee-container mb-6">
          <p class="!mb-2 mat-title text-darkGray !text-base">
            {{ businessPlan.isPremium() ? "Job Fees" : "Order Detail" }}
          </p>
          <mat-card class="mat-elevation-z0">
            <mat-card-content>
              <app-order-summary [shouldShowPromoCode]="false"></app-order-summary>
            </mat-card-content>
          </mat-card>
        </section>

        <!--            BILLING ADDRESS-->
        <section *ngIf="job.order && job.order.orderDetail.address" class="billing-address-container">
          <p class="!mb-2 mat-title text-darkGray !text-base">
            Billing Address
          </p>
          <mat-card class="mat-elevation-z0 flex flex-col">
            <div class="flex align-middle w-full gap-4 mb-2">
              <p class="mat-title !text-sub text-darkGray !mb-0 break-words">
                {{ job.order.orderDetail.address.fullName }}
              </p>
              &verbar;
              <p class="mat-title !text-sub !mb-0 text-darkGray">
                {{ job.order.orderDetail.address.phone }}
              </p>
            </div>
            <p class="!mb-1 w-[80%] break-words !text-sub">
              {{ commonHelper.renderAddress(job.order.orderDetail.address) }}
            </p>
          </mat-card>
        </section>
      </mat-card-content>
    </mat-card>
  </ng-container>
</div>

<div class="relative h-full w-full bg-lightGray">
  <ngx-doc-viewer
    [googleMaxChecks]="5"
    [googleCheckContentLoaded]="true"
    [googleCheckInterval]="3000"
    (loaded)="loadEnded()"
    [url]="url"
    class="flex-1 h-full w-full"
    viewer="google"
  ></ngx-doc-viewer>

  <mat-spinner *ngIf="loading$ | async as loading" [diameter]="60" class="screen-spinner"></mat-spinner>
</div>


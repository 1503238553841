import {BaseModel} from '@shared/models/base.model';
import {FileType, MetadataType} from '@generated/graphql';
import {User} from '@app/modules/user/models/user.model';
import {FileType as FileTypeModel} from './file-type.model';
import {fileTypeFactory} from "@modules/file/factories/file-type.factory";
import {userFactory} from "@modules/user/factories/user.factory";
import {basename, dirname} from "@shared/helpers/file.helpers";

export class File extends BaseModel implements FileType {
  static imageMimeTypes: string[] = [
    // Images
    'image/jpeg',
    'image/jpg',
    'image/png',
    'image/gif',
    'image/bmp',
    'image/webp',
  ]

  static videoMimeTypes: string[] = [
    'video/mp4',
    'video/x-msvideo',
    'video/x-matroska',
    'video/webm',
    'video/ogg',
  ]

  static documentMimeTypes: string[] = [
    'application/pdf',
    'text/plain',
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.ms-powerpoint',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation'
  ]

  static validMimeTypes: string[] = [
    // Images
    ...File.imageMimeTypes,
    // Video
    ...File.videoMimeTypes,
    // Documents
    ...File.documentMimeTypes
  ];

  static mimeTypesWithIcon: { types: string[], icon: string }[] = [
    {
      types: ['application/pdf'],
      icon: 'picture_as_pdf'
    },
    {
      types: File.documentMimeTypes,
      icon: 'description'
    },
    {
      types: File.imageMimeTypes,
      icon: 'image'
    },
    {
      types: File.videoMimeTypes,
      icon: 'movie'
    }
  ]

  static readonly defaultExtensions: string[] = [
    '.jpeg',
    '.png',
    '.jpg',
    '.gif',
    '.svg',
    '.xlsx',
    '.doc',
    '.docx',
    '.ppt',
    '.pptx',
    '.ods',
    '.odt',
    '.odp',
    '.pdf',
    '.csv',
    '.excel'
  ];

  id: string;
  createdBy: User;
  fileTypeId: string;
  fileType: FileTypeModel;
  hasFileId: string;
  hasFileType: string;
  metadata: MetadataType;
  mimeType: string;
  size: number;
  name: string;
  extension?: string;
  url: string;
  visibility: boolean;
  downloadUrl: string;
  path: string;
  isDirectory: boolean;
  _model: string;

  constructor(state: FileType) {
    super(state);

    this.fileType = fileTypeFactory.tryCreating(state.fileType)

    this.createdBy = userFactory.tryCreating(state.createdBy)
  }

  get label() {
    return this.isDirectory ? basename(dirname(this.path)) : this.metadata.originalName
  }

  get dirname () {
    return dirname(this.path)
  }

  get labelWithOutExtension() {
    if (this.extension) {
      return this.label.substring(0, this.label.lastIndexOf('.')) || this.label
    }

    return this.label
  }

  static validFiles(files: Blob[]): Blob[] {
    return files.filter(file => !File.validFile(file));
  }


  static validFile(file: Blob): boolean {
    return !File.validMimeTypes.includes(file.type);
  }

  getModelName(): string {
    return File.name;
  }

  canMoveTo(destination: File) {
    if (destination?.isDirectory) {
      return this.isDirectory
        ? destination.id !== this.id && dirname(dirname(this.path)) !== dirname(destination.path)
        : true
    }

    return false
  }
}

import {Inject, Injectable} from '@angular/core';
import {EntityService} from "@shared/services/entity.service";
import {EntityFeatureNameEnum} from "@shared/contracts/entity-store.contract";
import {
  ApplyPromotionMutation,
  CheckCanApplyPromotionByCodeQuery,
  CreatePromotionInput,
  CreatePromotionMutation,
  DeletePromotionMutation,
  IsPromotionCodeUniqueQuery,
  PromotionApplicationCheckResultType,
  PromotionByCodeQuery,
  PromotionsQuery,
  PromotionType,
  UpdatePromotionInput,
  UpdatePromotionMutation
} from "@generated/graphql";
import {Promotion} from "@modules/promotion/models/promotion.model";
import {Store} from "@ngrx/store";
import {PROMOTION_STORE, PromotionStore} from "@modules/promotion/stores/promotion.store";
import {ModelFactory} from '@shared/factories/model.factory';
import {promotionFactory} from "@modules/promotion/factories/promotion.factory";
import {map, Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class PromotionService extends EntityService<EntityFeatureNameEnum.Promotion, PromotionType, Promotion> {
  constructor(
    protected readonly store: Store,
    @Inject(PROMOTION_STORE) protected readonly entityStore: PromotionStore,
    private readonly updatePromotionMutation: UpdatePromotionMutation,
    private readonly createPromotionMutation: CreatePromotionMutation,
    private readonly getPromotionsQuery: PromotionsQuery,
    private readonly deletePromotionMutation: DeletePromotionMutation,
    private readonly isPromotionCodeUniqueQuery: IsPromotionCodeUniqueQuery,
    private readonly getPromotionByCode: PromotionByCodeQuery,
    private readonly checkCanApplyPromotionByCodeQuery: CheckCanApplyPromotionByCodeQuery,
    private readonly applyPromotionMutation: ApplyPromotionMutation,
  ) {
    super(store, entityStore);
  }

  get factory(): ModelFactory<PromotionType, Promotion> {
    return promotionFactory;
  }

  getPromotions(searchText: string): Observable<PromotionType[]> {
    return this.getPromotionsQuery.fetch({searchText}, {fetchPolicy: 'no-cache'}).pipe(
      map((response) => response.data.promotions),
    );
  }

  createPromotion(input: CreatePromotionInput): Observable<PromotionType> {
    return this.createPromotionMutation.mutate({input}).pipe(
      map((response) => response.data.createPromotion),
    );
  }

  updatePromotion(input: UpdatePromotionInput): Observable<PromotionType> {
    return this.updatePromotionMutation.mutate({input}).pipe(
      map((response) => response.data.updatePromotion),
    );
  }

  deletePromotion(id: string): Observable<boolean> {
    return this.deletePromotionMutation.mutate({id}).pipe(
      map((response) => response.data.deletePromotion),
    );
  }

  isPromotionCodeUnique(code: string): Observable<boolean> {
    return this.isPromotionCodeUniqueQuery.fetch({code}, {fetchPolicy: 'no-cache'}).pipe(
      map(response => response.data.isPromotionCodeUnique) // Assuming your backend returns a boolean
    );
  }

  findByCode(code: string): Observable<PromotionType | null> {
    return this.getPromotionByCode.fetch({code}).pipe(
      map((response) => response.data.promotionByCode),
    );
  }

  checkCanApplyPromotionByCode(code: string, orderId: string): Observable<PromotionApplicationCheckResultType> {
    return this.checkCanApplyPromotionByCodeQuery.fetch({code, orderId}).pipe(
      map((response) => response.data.checkCanApplyPromotionByCode),
    );
  }

  applyPromotion(promotionId: string, orderId: string): Observable<boolean> {
    return this.applyPromotionMutation.mutate({promotionId, orderId}).pipe(
      map((response) => response.data.applyPromotion),
    );
  }
}

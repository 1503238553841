<ng-container *ngrxLet="activeUser$ as activeUser">
  <ng-container *ngrxLet="businessProfile$ as businessProfile">
    <div class="review-incorporation-request-wrapper">
      <!--    KYC REMINDER-->
      <ng-container *ngIf="activeUser.isCustomer()">
        <app-kyc-reminder-message [companyID]="job.company.id"></app-kyc-reminder-message>
      </ng-container>

      <!--    COMPANY DETAIL-->
      <section class="main-request-container mb-6">
        <app-info-section-wp class="!text-sub" sectionTitle="Company Detail">
          <!--        COMPANY NAME-->
          <section class="item">
            <div class="w-1/4 mr-3">
              <p class="mat-subheading-1 !text-sub text-darkGray !mb-0">
                Company Name&colon;
              </p>
            </div>
            <div class="w-3/4 !text-sub">
              <div class="flex flex-col">
                <ng-container *ngIf="isRegisterCompany()">
                  <p>
                    <span
                      class="mat-subheading-1 !text-sub text-darkGray !mb-2">&bullet; First Choice&colon;&ngsp;</span>
                    <span>{{ companyInput.name }}</span>
                  </p>
                  <p>
                  <span
                    class="mat-subheading-1 !text-sub text-darkGray !mb-2">&bullet; Second Choice&colon;&ngsp;</span>
                    <span>{{ companyInput.secondName }}</span>
                  </p>
                  <p>
                    <span
                      class="mat-subheading-1 !text-sub text-darkGray !mb-2">&bullet; Third Choice&colon;&ngsp;</span>
                    <span>{{ companyInput.thirdName }}</span>
                  </p>
                </ng-container>
                <ng-container *ngIf="isTransferCompany()">
                  <p class="!mb-0">{{ companyInput.name }}</p>
                </ng-container>
              </div>
            </div>
          </section>

          <!--        COMPANY WEBSITE-->
          <section class="item">
            <div class="w-1/4 mr-3">
              <p class="mat-subheading-1 !text-sub text-darkGray !mb-0">
                Company website&colon;
              </p>
            </div>
            <div class="w-3/4 !text-sub">
              <div class="flex flex-col">
                <p class="!mb-0">{{ companyInput.website ? companyInput.website : 'N/A' }}</p>
              </div>
            </div>
          </section>

          <!--        TRANSFER COMPANY INFO-->
          <ng-container *ngIf="isTransferCompany()">
            <!--            REGISTRATION NUMBER-->
            <section class="item">
              <div class="w-1/4 mr-3">
                <p class="mat-subheading-1 !text-sub text-darkGray !mb-0">
                  Registration No&colon;
                </p>
              </div>
              <div class="w-3/4 !text-sub">
                <div class="flex flex-col">
                  <p class="!mb-0">{{ companyInput.registrationNumber }}</p>
                </div>
              </div>
            </section>
            <!--            INCORPORATION DATE-->
            <section class="item">
              <div class="w-1/4 mr-3">
                <p class="mat-subheading-1 !text-sub text-darkGray !mb-0">
                  Incorporation date&colon;
                </p>
              </div>
              <div class="w-3/4 !text-sub">
                <div class="flex flex-col">
                  <p class="!mb-0">
                    {{ companyInput.registrationDate | date: "d MMM, YYYY" }}
                  </p>
                </div>
              </div>
            </section>
          </ng-container>

          <!--        Country of incorporation-->
          <section class="item">
            <div class="w-1/4 mr-3">
              <p class="mat-subheading-1 !text-sub text-darkGray !mb-0">
                Country of incorporation&colon;
              </p>
            </div>
            <div class="w-3/4 !text-sub">
              <div class="flex flex-col">
                <app-country-flag [id]="companyInput.countryId" [ngClass]="'!text-darkGray'"
                                  showCountryName></app-country-flag>
              </div>
            </div>
          </section>

          <!--        COMPANY ADDRESS-->
          <section class="item">
            <div class="w-1/4 mr-3">
              <p class="mat-subheading-1 !text-sub text-darkGray !mb-0">
                Company address&colon;
              </p>
            </div>
            <div class="w-3/4 !text-sub">
              <p class="!mb-0">{{ renderRegisteredAddress() }}</p>
            </div>
          </section>

          <!--        COMPANY ACTIVITY-->
          <section class="item">
            <div class="w-1/4 mr-3">
              <p class="mat-subheading-1 !text-sub text-darkGray !mb-0">
                Company activity&colon;
              </p>
            </div>
            <div class="w-3/4 !text-sub">
              <div class="flex flex-col">
                <p class="!mb-0">{{ companyInput.companyActivity }}</p>
              </div>
            </div>
          </section>

          <!--            HAVE SPECIAL LICENSE-->
          <section *ngIf="isTransferCompany()" class="item">
            <div class="w-1/4 mr-3">
              <p class="mat-subheading-1 !text-sub text-darkGray !mb-0">
                Have special licenses for business&colon;
              </p>
            </div>
            <div class="w-3/4 !text-sub">
              <div class="flex flex-col">
                <p class="!mb-0">
                  {{ companyInput.specialLicenseDocument ? "Yes" : "No" }}
                </p>
              </div>
            </div>
          </section>

          <!--            NUMBER OF STAFF-->
          <section *ngIf="isTransferCompany()" class="item">
            <div class="w-1/4 mr-3">
              <p class="mat-subheading-1 !text-sub text-darkGray !mb-0">
                Number of employees&colon;
              </p>
            </div>
            <div class="w-3/4 !text-sub">
              <div class="flex flex-col">
                <p class="!mb-0">{{ companyInput.numberOfEmployees | number }}</p>
              </div>
            </div>
          </section>

          <!--SECRETARY-->
          <section class="item">
            <div class="w-1/4 mr-3">
              <p class="mat-subheading-1 !text-sub text-darkGray !mb-0">
                Company secretary:
              </p>
            </div>
            <div class="w-3/4 !text-sub">
              <p class="!mb-0 truncate">{{ businessProfile.name }}</p>
            </div>
          </section>

          <!--        FYE DATE-->
          <section class="item">
            <div class="w-1/4 mr-3">
              <p class="mat-subheading-1 !text-sub text-darkGray !mb-0">
                FYE Date&colon;
              </p>
            </div>
            <div class="w-3/4 !text-sub">
              <div class="flex flex-col">
                <p class="!mb-0">{{ companyInput.fyeDate | date: "d MMM" }}</p>
              </div>
            </div>
          </section>


        </app-info-section-wp>
      </section>
      <!--    END COMPANY DETAIL-->

      <!-- COMPANY CAPITALS -->
      <section class="main-request-container mb-6">
        <app-info-section-wp sectionTitle="Company Capital">
          <app-registered-capital appearance="viewOnly"></app-registered-capital>
        </app-info-section-wp>
      </section>
      <!-- END COMPANY CAPITALS -->

      <!-- COMPANY SHAREHOLDERS -->
      <section class="main-request-container mb-6">
        <app-info-section-wp sectionTitle="Shareholders">
          <app-shareholder-table appearance="viewOnly"></app-shareholder-table>
        </app-info-section-wp>
      </section>
      <!-- END COMPANY SHAREHOLDERS -->

      <!-- COMPANY DIRECTORS -->
      <section class="main-request-container mb-6">
        <app-info-section-wp sectionTitle="Directors">
          <app-directors-table appearance="viewOnly"></app-directors-table>
        </app-info-section-wp>
      </section>
      <!-- END COMPANY DIRECTORS -->
    </div>
  </ng-container>
</ng-container>

import {ModelFactory} from '@shared/factories/model.factory';
import {DocumentTemplateType} from '@generated/graphql';
import {DocumentTemplate} from "@modules/document-template/models/document-template.model";

export class DocumentTemplateFactory extends ModelFactory<DocumentTemplateType, DocumentTemplate> {
  create(state: DocumentTemplateType): DocumentTemplate {
    return new DocumentTemplate(state);
  }
}

export const documentTemplateFactory = new DocumentTemplateFactory();

<div class="text-right my-4">
  <button mat-raised-button color="primary" (click)="create()">Create</button>
</div>

<div class="document-template-table-container">
  <div class="table-container mat-elevation-z1" *ngrxLet="dataSource$ as dataSource">
    <table mat-table [dataSource]="dataSource" class="table-custom">
      <ng-container matColumnDef="name">
        <th mat-header-cell class="!text-white" *matHeaderCellDef> Name</th>
        <td mat-cell *matCellDef="let element" class="truncate"> {{element.name}} </td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th mat-header-cell class="!text-white" *matHeaderCellDef> Actions</th>
        <td mat-cell *matCellDef="let element">
          <div class="flex gap-2">
            <button mat-icon-button color="primary" [matTooltip]="'Compile'" (click)="compile(element)">
              <mat-icon>build</mat-icon>
            </button>
            <button mat-icon-button color="primary" (click)="view(element)">
              <mat-icon>remove_red_eye</mat-icon>
            </button>
            <button mat-icon-button color="primary" (click)="download(element)">
              <mat-icon>download</mat-icon>
            </button>
            <button mat-icon-button color="primary" (click)="update(element)">
              <mat-icon>edit</mat-icon>
            </button>
            <button mat-icon-button color="warn" (ok)="delete(element)" appConfirmDialog>
              <mat-icon>delete</mat-icon>
            </button>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="createdBy">
        <th mat-header-cell class="!text-white" *matHeaderCellDef> Created By</th>
        <td mat-cell *matCellDef="let element"> {{element.createdBy.profile.fullName}} </td>
      </ng-container>

      <ng-container matColumnDef="createdAt">
        <th mat-header-cell
            *matHeaderCellDef
            class="!text-center !text-white">Created Date
        </th>
        <td mat-cell
            *matCellDef="let element"
            class="text-center">
          {{element.createdAt | date }}
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns" class="bg-primary-800 text-white"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="data-row"></tr>
      <tr class="mat-row"
          *matNoDataRow>
        <td [colSpan]="displayedColumns.length">
          <app-table-loading *ngIf="(loading$ | async); else noRecord"></app-table-loading>
          <ng-template #noRecord>
            <table-no-record [notFoundMessage]="'No templates'"></table-no-record>
          </ng-template>
        </td>
      </tr>
    </table>

  </div>
</div>

import {FileTypeEnum} from '@generated/graphql';

export type UploadFileType = {
  type: FileTypeEnum;
  file: File;
};

export const acceptTypes = [
  'image/*',
  'application/pdf',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.ms-powerpoint',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  'text/plain'
];

export const generalAcceptFileTypes = acceptTypes.join(',')

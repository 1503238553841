<ng-template [ngIf]="loading">
  <div class="block w-full">
    <mat-spinner class="m-auto"
                 diameter="32"></mat-spinner>
  </div>
</ng-template>

<ng-container *ngTemplateOutlet="(canSetupPaymentMethod$ | async) ? paymentForm : cantProcessPayments"></ng-container>

<ng-template #paymentForm>
  <form (ngSubmit)="submit()"
        id="payment-form">
    <div id="link-authentication-element">
      <!--Stripe.js injects the Link Authentication Element-->
    </div>
    <div id="payment-element">
      <!--Stripe.js injects the Payment Element-->
    </div>
    <div *ngIf="!loading"
         class="text-right">
      <button class="py-2"
              color="primary"
              id="submit"
              mat-raised-button>Add card
      </button>
    </div>
  </form>
</ng-template>

<ng-template #cantProcessPayments>
  <app-callout class="block my-2"
               message="Coming Soon!"></app-callout>
</ng-template>



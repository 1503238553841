import {BaseModel} from '@shared/models/base.model';
import {JobDetailType, JobStatusEnum, JobType} from '@generated/graphql';
import {User} from '@modules/user/models/user.model';
import {jobDetailFactory} from '@modules/job/factories/job-detail.factory';
import {userFactory} from '@modules/user/factories/user.factory';
import {Company} from '@modules/company/models/company.model';
import {companyFactory} from '@modules/company/factories/company.factory';
import {Service} from '@modules/service/models/service.model';
import {ServiceSupportedCountry} from '@modules/service/models/service-supported-country.model';
import {serviceFactory} from '@modules/service/factories/service.factory';
import {serviceSupportedCountryFactory} from '@modules/service/factories/service-supported-country.factory';
import {SupportedCountry} from '@modules/supported-country/models/supported-country.model';
import {supportedCountryFactory} from '@modules/supported-country/factories/supported-country.factory';
import {ExtraJob} from '@modules/extra-job/models/extra-job.model';
import {extraJobFactory} from '@modules/extra-job/factories/extra-job.factory';
import {JobObjectDetail} from './job-object-detail.model';
import {Order} from '@modules/order/models/order.model';
import {orderFactory} from '@modules/order/factories/order.factory';
import {startCase} from 'lodash-es';
import {AppStatusType} from '@shared/component/common/status/status.component';
import {fileFactory} from '@modules/file/factories/file.factory';
import {File} from '@app/modules/file/models/file.model';
import {
  addAlphabetPrefix,
  applyPlaceValue,
  DocumentTemplate,
} from "@modules/document-template/models/document-template.model";
import {documentTemplateFactory} from "@modules/document-template/factories/document-template.factory";
import {DateTime} from "luxon";
import * as converter from 'number-to-words'

export class Job extends BaseModel implements JobType {
  id: string;
  matter: string;
  detail: JobDetailType;
  detailObject: JobObjectDetail;
  experts: User[];
  customers: User[];
  participants: User[];
  status: JobStatusEnum;
  company: Company;

  supportedCountry: SupportedCountry;
  service: Service;
  serviceSupportedCountry: ServiceSupportedCountry;
  companyId?: string;
  createdBy: User;
  description?: string;
  extraJobs: ExtraJob[];
  order?: Order;
  _model: string;
  estimatedTime: string;
  canClose: boolean;
  canConfirm: boolean;
  documents: File[];
  parentId?: string;
  parent?: InheritJob;
  documentTemplates: DocumentTemplate[]

  // payments: Payment[]

  constructor(state: JobType) {
    super(state);

    this.detail = state.detail ? jobDetailFactory.create(state.detail) : null;

    if (state.rawDetail) {
      const parsedDetail = JSON.parse(state.rawDetail) || {};
      this.detailObject = new JobObjectDetail(parsedDetail);
    }

    this.customers = state.customers
      ? state.customers.map((customer) => userFactory.create(customer))
      : [];

    this.experts = state.experts
      ? state.experts.map((expert) => userFactory.create(expert))
      : [];

    this.participants = state.participants
      ? state.participants.map((participant) => userFactory.create(participant))
      : [];

    this.company = state.company ? companyFactory.create(state.company) : null;

    this.supportedCountry = state.supportedCountry
      ? supportedCountryFactory.create(state.supportedCountry)
      : null;

    this.service = state.service ? serviceFactory.create(state.service) : null;

    this.serviceSupportedCountry = state.serviceSupportedCountry
      ? serviceSupportedCountryFactory.create(state.serviceSupportedCountry)
      : null;

    this.createdBy = state.createdBy
      ? userFactory.create(state.createdBy)
      : null;

    this.extraJobs = state.extraJobs
      ? state.extraJobs.map((item) => extraJobFactory.create(item))
      : [];

    this.order = state.order ? orderFactory.create(state.order) : null;

    this.documents = fileFactory.createEntities(state.documents);

    this.documentTemplates = documentTemplateFactory.createEntities(state.documentTemplates)

    if (state.parent) {
      this.parent = new InheritJob(state.parent);
    }
  }

  get scopeOfWork(): string {
    return this.serviceSupportedCountry?.scopeOfWork ?? 'None';
  }

  getModelName(): string {
    return Job.name;
  }

  isOngoing(): boolean {
    return this.status === JobStatusEnum.Ongoing;
  }

  getLongMatter(): string {
    return [this.matter, this.company?.name]
      .filter((item) => !!item)
      .join(' - ');
  }

  getMatterDisplay(): string {
    return this.matter ? startCase(this.matter) : '';
  }

  public get createdDate() {
    return this.createdAt;
  }

  public renderStatusStyle(): AppStatusType {
    switch (this.status) {
      case JobStatusEnum.New:
        return 'new';
      case JobStatusEnum.Ongoing:
        return 'new-light';
      case JobStatusEnum.Done:
        return 'success';
      case JobStatusEnum.Pending:
        return 'hold';
      case JobStatusEnum.Draft:
        return 'hold';
      case JobStatusEnum.Closed:
        return 'cancelled';
      case JobStatusEnum.Unassigned:
        return 'action-light';
      default:
        return 'default';
    }
  }

  renderStatusLabel(): string {
    return startCase(this.status.toLowerCase());
  }

  get templateData() {
    const capital = this.company.companyCapitals[0]

    const companyCapital = capital ? {
      totalAmount: capital.totalAmount,
      currency: capital.currency,
      totalAmountWithCurrencyToWords: converter.toWords(capital.totalAmount) + " " + capital.currency
    } : null

    const company = {
      name: this.company.name,
      registrationDate: this.company.registrationDate ? DateTime.fromJSDate(this.company.registrationDate).toFormat('yyyy-MM-dd') : '',
      registrationNumber: this.company.registrationNumber
    }

    const corporateShareholders = this.company.corporateShareholders.map(i => ({
      name: i.name,
      totalOrdinaryShares: i.totalOrdinaryShares
    }))

    const individualShareholders = this.company.individualShareholders.map(i => ({
      name: i.name,
      totalOrdinaryShares: i.totalOrdinaryShares
    }))

    const totalOrdinaryShares = [
      ...corporateShareholders,
      ...individualShareholders
    ].reduce((result, item) => {
      return result + item.totalOrdinaryShares
    }, 0)

    const companySecretaries = this.company.companySecretaries.map(s => s.officer.name.toUpperCase())

    return applyPlaceValue({
      company,
      companySecretaries,
      corporateShareholders,
      individualShareholders,
      totalOrdinaryShares,
      companyCapital,
      directorNames: addAlphabetPrefix(this.company.directors.map(di => di.name)),
      registeredOfficeAddress: this.company.registeredAddressToString,
      placeWhereMinutesBook: '',
      financialYearEndDate: DateTime.fromJSDate(new Date(this.company.fyeDate)).toFormat('d MMMM, yyyy')
    })
  }
}

export interface JobServicesOrder {
  mainServices: OrderDetail[];
  additionalServices: OrderDetail[];
  // currency: string,
  // taxRate: number
}

export interface OrderDetail {
  service: ServiceSupportedCountry;
  quantity: number;
}

export class InheritJob extends Job {
}

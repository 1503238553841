import {NgModule, Type} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MetaReducer, StoreModule} from '@ngrx/store';
import {StoreDevtoolsModule} from '@ngrx/store-devtools';
import {environment} from '@environment/environment';
import {EffectsModule} from '@ngrx/effects';
import {storeDebugMiddleware} from '@utils/store/middlewares/store-debug.middleware';
import {AuthInitializerEffects} from '@modules/auth/store/effects/auth-initializer.effects';
import {LoginEffects} from '@modules/auth/store/effects/login.effects';
import {LogoutEffects} from '@modules/auth/store/effects/logout.effects';
import {AuthUserEffects} from '@modules/auth/store/effects/auth-user.effects';
import {authFeature} from '@modules/auth/store/features/auth.feature';
import {AuthEffects} from '@modules/auth/store/effects/auth.effects';
import {RegisterEffects} from '@modules/auth/store/effects/register.effects';
import {ResendEmailVerificationEffects} from '@modules/auth/store/effects/resend-email-verification.effects';
import {VerifyEmailEffects} from '@modules/auth/store/effects/verify-email.effects';
import {ResettingPasswordEffects} from '@modules/auth/store/effects/resetting-password.effects';
import {ImpersonateEffects} from '@modules/auth/store/effects/impersonate.effects';
import {CountryInitializerEffects} from '@modules/country/store/effects/country-initializer.effects';
import {
  SupportedCountryInitializerEffects
} from '@modules/supported-country/store/effects/supported-country-initializer.effects';
import {
  BusinessProfileEffects
} from '@modules/account-settings/pages/business-profile/store/effects/business-profile.effects';
import {COUNTRY_STORE_CONFIG, countryStore,} from '@modules/country/store/country.store';
import {SERVICE_CATEGORY_STORE_CONFIG, serviceCategoryStore,} from '@modules/service/store/service-category.store';
import {
  SERVICE_SUPPORTED_COUNTRY_STORE_CONFIG,
  serviceSupportedCountryStore,
} from '@modules/service/store/service-supported-country.store';
import {
  SUPPORTED_COUNTRY_STORE_CONFIG,
  supportedCountryStore,
} from '@modules/supported-country/store/supported-country.store';
import {PAYMENT_TYPE_STORE_CONFIG, paymentTypeStore,} from '@modules/payment/store/payment-type.store';
import {PAYMENT_PROVIDER_STORE_CONFIG, paymentProviderStore,} from '@modules/payment/store/payment-provider.store';
import {SERVICE_STORE_CONFIG, serviceStore,} from '@modules/service/store/service.store';
import {
  WIRE_TRANSFER_BANK_STORE_CONFIG,
  wireTransferBankStore,
} from '@modules/wire-transfer-bank/store/wire-transfer-bank.store';
import {
  BUSINESS_PROFILE_STORE_CONFIG,
  businessProfileStore,
} from '@modules/account-settings/pages/business-profile/store/business-profile.store';
import {EXTRA_JOB_STORE_CONFIG, extraJobStore,} from '@modules/extra-job/store/extra-job.store';
import {PAYMENT_STORE_CONFIG, paymentStore,} from '@modules/payment/store/payment.store';
import {BILLING_ADDRESS_STORE_CONFIG, billingAddressStore,} from '@modules/address/store/billing-address.store';
import {createJobFeature} from '@modules/job/store/create-job.feature';
import {
  OFFICER_REQUIRED_DOCUMENT_STORE_CONFIG,
  officerRequiredDocumentStore,
} from '@modules/officer/store/officer-required-document.store';
import {PAYMENT_USER_STORE_CONFIG, paymentUserStore,} from '@modules/payment/store/payment-user.store';
import {CUSTOMER_STORE_CONFIG, customerStore,} from '@modules/user/store/customer/customer.store';
import {EXPERT_STORE_CONFIG, expertStore,} from '@modules/user/store/expert/expert.store';
import {JOB_STORE_CONFIG, jobStore} from '@modules/job/store/job.store';
import {COMPANY_STORE_CONFIG, companyStore,} from '@modules/company/store/company.store';
import {ORDER_STORE_CONFIG, orderStore,} from '@modules/order/store/order.store';
import {documentFeatureKey, documentReducer,} from './modules/company/store/documents/document.reducer';
import {ADDRESS_TYPE_STORE_CONFIG, addressTypeStore} from "@modules/address/store/address-type.store";
import {PROMOTION_STORE_CONFIG, promotionStore} from "@modules/promotion/stores/promotion.store";

export const metaReducers: MetaReducer<any>[] = [storeDebugMiddleware];

// export const ROOT_REDUCERS = {
//   createJob: createJobReducer,
// };

export const ROOT_EFFECTS: Type<any>[] = [
  AuthInitializerEffects,
  CountryInitializerEffects,
  SupportedCountryInitializerEffects,
  BusinessProfileEffects,
  // ServiceSupportedCountryInitializerEffect,
];

@NgModule({
  declarations: [],
  exports: [StoreModule, EffectsModule],
  imports: [
    CommonModule,

    /**
     * Don't write reducers in Root because there are some issues can cause in Prod mode
     * Please have a look at the way we defined current features in app
     */
    StoreModule.forRoot(
      {},
      {
        metaReducers,
      },
    ),

    EffectsModule.forRoot(ROOT_EFFECTS),

    StoreModule.forFeature(authFeature),
    StoreModule.forFeature(createJobFeature),
    StoreModule.forFeature(countryStore.feature),
    StoreModule.forFeature(supportedCountryStore.feature),
    StoreModule.forFeature(serviceCategoryStore.feature),
    StoreModule.forFeature(serviceSupportedCountryStore.feature),
    StoreModule.forFeature(paymentTypeStore.feature),
    StoreModule.forFeature(paymentProviderStore.feature),
    StoreModule.forFeature(serviceStore.feature),
    StoreModule.forFeature(wireTransferBankStore.feature),
    StoreModule.forFeature(paymentUserStore.feature),
    StoreModule.forFeature(customerStore.feature),
    StoreModule.forFeature(expertStore.feature),
    StoreModule.forFeature(jobStore.feature),
    StoreModule.forFeature(companyStore.feature),
    StoreModule.forFeature(orderStore.feature),
    StoreModule.forFeature(businessProfileStore.feature),
    StoreModule.forFeature(extraJobStore.feature),
    StoreModule.forFeature(paymentStore.feature),
    StoreModule.forFeature(billingAddressStore.feature),
    StoreModule.forFeature(officerRequiredDocumentStore.feature),
    StoreModule.forFeature(addressTypeStore.feature),
    StoreModule.forFeature(documentFeatureKey, documentReducer),
    StoreModule.forFeature(promotionStore.feature),

    EffectsModule.forFeature([
      LoginEffects,
      LogoutEffects,
      AuthUserEffects,
      RegisterEffects,
      ResendEmailVerificationEffects,
      VerifyEmailEffects,
      ResettingPasswordEffects,
      ImpersonateEffects,
      AuthEffects,
    ]),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: environment.production,
    }),
  ],
  providers: [
    COUNTRY_STORE_CONFIG,
    SUPPORTED_COUNTRY_STORE_CONFIG,
    SERVICE_CATEGORY_STORE_CONFIG,
    SERVICE_SUPPORTED_COUNTRY_STORE_CONFIG,
    PAYMENT_TYPE_STORE_CONFIG,
    PAYMENT_PROVIDER_STORE_CONFIG,
    SERVICE_STORE_CONFIG,
    WIRE_TRANSFER_BANK_STORE_CONFIG,
    PAYMENT_USER_STORE_CONFIG,
    CUSTOMER_STORE_CONFIG,
    EXPERT_STORE_CONFIG,
    JOB_STORE_CONFIG,
    COMPANY_STORE_CONFIG,
    ORDER_STORE_CONFIG,
    BUSINESS_PROFILE_STORE_CONFIG,
    EXTRA_JOB_STORE_CONFIG,
    PAYMENT_STORE_CONFIG,
    BILLING_ADDRESS_STORE_CONFIG,
    OFFICER_REQUIRED_DOCUMENT_STORE_CONFIG,
    ADDRESS_TYPE_STORE_CONFIG,
    PROMOTION_STORE_CONFIG,
  ],
})
export class AppStoreModule {
}

import {BaseModel} from '@shared/models/base.model';
import {
  CompanyCapitalType, CompanyRegisteredAddresStatusEnum,
  CompanyRegisteredAddressType,
  CompanyStatusEnum,
  CompanyType,
  GstStatusEnum,
  KycStatusEnum,
} from '@generated/graphql';
import {Country} from '@modules/country/models/country.model';
import {User} from '@modules/user/models/user.model';
import {countryFactory} from '@modules/country/factories/country.factory';
import {userFactory} from '@modules/user/factories/user.factory';
import {CompanySecretary} from '@modules/company/models/company-secretary.model';
import {companySecretaryFactory} from '@modules/company/factories/company-secretary.factory';
import {CompanyAuditor} from '@modules/company/models/company-auditor.model';
import {companyAuditorFactory} from '@modules/company/factories/company-auditor.factory';
import {companyShareholderFactory} from '@modules/company/factories/company-shareholder.factory';
import {CompanyShareholder} from '@modules/company/models/company-shareholder.model';
import {CompanyDirector} from "@modules/company/models/company-director.model";
import {companyDirectorFactory} from "@modules/company/factories/company-director.factory";
import {MissingKycDocument} from "@modules/officer/models/missing-kyc-document.model";
import {missingKycDocumentFactory} from "@modules/officer/factories/missing-kyc-document.factory";
import {snakeCase} from "lodash-es";
import {CompanyIndirectShareholder} from "@modules/company/models/company-indirect-shareholder.model";
import {Corporate} from "@modules/company/models/corporate.model";
import {corporateFactory} from "@modules/company/factories/corporate.factory";
import {companyIndirectShareholderFactory} from "@modules/company/factories/company-indirect-shareholder.factory";
import {DateTime} from "luxon";
import {Job} from "@modules/job/models/job.model";
import {jobFactory} from "@modules/job/factories/job.factory";
import {GstReturn} from "@modules/gst-return/models/gst-return.model";
import {gstReturnFactory} from "@modules/gst-return/factories/gst-return.factory";
import {companyGstReturnFactory} from "@modules/company/factories/company-gst-return.factory";
import {CompanyGstReturn} from "@modules/company/models/company-gst-return.model";

export class Company extends BaseModel implements CompanyType {
  id: string;
  kycStatus: KycStatusEnum;
  gstStatus: GstStatusEnum;
  fyeDate: Date;
  registrationDate?: Date;
  registrationNumber?: string;
  name: string;
  status: CompanyStatusEnum;
  country: Country;
  createdBy: User;
  owner?: User;
  users: User[];
  companyActivity?: string;
  companyCapitals?: CompanyCapitalType[];
  createdById: string;
  directors?: CompanyDirector[];
  numberOfEmployees?: number;
  registeredAddresses?: CompanyRegisteredAddressType[];
  shareholders?: CompanyShareholder[];
  companySecretaries: CompanySecretary[];
  companyAuditors: CompanyAuditor[];
  canCreateCompanyAuditor: boolean;
  canCreateCompanySecretary: boolean;
  canConfirmKyc: boolean;
  canSubmitKyc: boolean;
  website?: string;
  secondName?: string;
  thirdName?: string;
  missingKycDocuments?: MissingKycDocument[]
  indirectShareholders?: CompanyIndirectShareholder[]
  shareholdersForOrganizationChart?: Corporate[]
  newChangeFYEDateJob?: Job
  newGstRegistrationJob?: Job
  gstReturns?: GstReturn[]
  companyGstReturn?: CompanyGstReturn
  companyGstReturnForOwner?: CompanyGstReturn
  experts?: User[]
  customers?: User[]
  newGstReturnJobs?: Job[]
  rootDocumentsPath: string

  get companyName(): string {
    return this.name;
  }

  get countryName(): string {
    return this.country.name;
  }

  get organizationChartFileName(): string {
    return snakeCase(`${this.name}_organization_chart`);
  }

  get isGstRegistered() {
    return this.gstStatus === GstStatusEnum.Registered
  }

  constructor(state: CompanyType) {
    super(state);

    this.country = countryFactory.tryCreating(state.country);

    this.createdBy = userFactory.tryCreating(state.createdBy);

    this.owner = userFactory.tryCreating(state.owner);

    this.users = userFactory.createEntities(state.users);

    this.companySecretaries = companySecretaryFactory.createEntities(
      state.companySecretaries,
    );

    this.companyAuditors = companyAuditorFactory.createEntities(
      state.companyAuditors,
    );

    this.shareholders = companyShareholderFactory.createEntities(
      state.shareholders,
    );

    this.directors = companyDirectorFactory.createEntities(state.directors);

    this.missingKycDocuments = missingKycDocumentFactory.createEntities(state.missingKycDocuments)

    this.shareholdersForOrganizationChart = corporateFactory.createEntities(state.shareholdersForOrganizationChart)

    this.indirectShareholders = companyIndirectShareholderFactory.createEntities(state.indirectShareholders)

    this.newChangeFYEDateJob = jobFactory.tryCreating(state.newChangeFYEDateJob)

    this.newGstRegistrationJob = jobFactory.tryCreating(state.newGstRegistrationJob)

    this.gstReturns = gstReturnFactory.createEntities(state.gstReturns)

    this.companyGstReturn = companyGstReturnFactory.tryCreating(state.companyGstReturn)

    this.companyGstReturnForOwner = companyGstReturnFactory.tryCreating(state.companyGstReturnForOwner)

    this.experts = userFactory.createEntities(state.experts)

    this.customers = userFactory.createEntities(state.customers)
  }

  get corporates(): Corporate[] {
    const getShareholders = (item: Corporate): Corporate[] => {
      const sh: Corporate[] = []

      sh.push(item)

      if (item.shareholders.length) {
        item.shareholders.forEach(i => {
          sh.push(...getShareholders(i))
        })
      }

      return sh
    }

    return this.shareholders.map(sh => getShareholders(sh)).flat()
  }

  getModelName(): string {
    return Company.name;
  }

  public getNextFYEValue(): Date {
    const currentFYEDate = DateTime.fromFormat(
      this.fyeDate.toString(),
      'yyyy-MM-dd HH:mm:ss',
    );

    const now = DateTime.now();

    const incorporationDate = this.registrationDate
      ? DateTime.fromFormat(
        this.registrationDate.toString(),
        'yyyy-MM-dd HH:mm:ss',
      )
      : null;

    const result = currentFYEDate;

    if (incorporationDate) {
      if (now.year < incorporationDate.year) {
        result.set({year: incorporationDate.year});
      } else {
        result.set({year: now.year});
      }
    } else {
      result.set({year: now.year});
    }

    return result.toJSDate();
  }


  companyPath(): string {
    return `${window.origin}/companies/${this.id}`;
  }

  // TODO hardcoded
  get isECISupported(): boolean {
    return this.country.isSingapore
  }

  // TODO hardcoded
  get isFormCSupported(): boolean {
    return this.country.isSingapore
  }

  // TODO hardcoded
  get isProfitsTaxReturnSupported(): boolean {
    return this.country.isHongKong
  }

  get registeredAddress () {
    const data = this.registeredAddresses.filter(a => a.status === CompanyRegisteredAddresStatusEnum.Active)

    return data.length ? data[0] : null
  }

  get registeredAddressToString () {
    if (this.registeredAddress) {
      const {apartment, address, country, city, state, zip} = this.registeredAddress.address

      return [apartment, address, city, state, country?.name, zip]
        .filter(Boolean)
        .join(', ');
    }

    return ''
  }

  get corporateShareholders () {
    return this.shareholders.filter(sh => sh.officer.isCorporate)
  }

  get individualShareholders () {
    return this.shareholders.filter(sh => sh.officer.isIndividual)
  }
}

import {ModelFactory} from "@shared/factories/model.factory";
import {PromotionType} from "@generated/graphql";
import {Promotion} from "@modules/promotion/models/promotion.model";

export class PromotionFactory extends ModelFactory<PromotionType, Promotion> {
  create(state: PromotionType): Promotion {
    return new Promotion(state);
  }

  createEntities(state: PromotionType[]): Promotion[] {
    return state ? state.map(item => this.create(item)) : []
  }

  fake(): Promotion {
    throw new Error('This method is not implemented')
  }
}

export const promotionFactory = new PromotionFactory()

import {BaseModel} from "@shared/models/base.model";
import {PromotionStatusEnum, PromotionType, PromotionTypeEnum} from "@generated/graphql";
import {ServiceCategory} from "@modules/service/models/service-category.model";
import {serviceCategoryFactory} from "@modules/service/factories/service-category.factory";
import {userFactory} from "@modules/user/factories/user.factory";
import {User} from "@modules/user/models/user.model";

export class Promotion extends BaseModel implements PromotionType {
  id: string;
  name: string;
  code: string;
  discount: number;
  usagesLimitation: number;
  type: PromotionTypeEnum;
  status: PromotionStatusEnum;
  expiredDate?: Date;
  usedCount: number;
  createdBy: User;
  serviceCategories: ServiceCategory[];
  isLimitReached: boolean;
  isExpired: boolean;
  isActive: boolean;

  constructor(state: PromotionType) {
    super(state);

    this.createdBy = state.createdBy ? userFactory.create(state.createdBy) : null

    this.serviceCategories = state.serviceCategories
      ? state.serviceCategories.map((item) => serviceCategoryFactory.create(item))
      : [];
  }

  getModelName(): string {
    return Promotion.name;
  }

}

import {Component, Input, OnInit} from '@angular/core';
import {Country} from '@modules/country/models/country.model';
import {BehaviorSubject, Observable, tap} from 'rxjs';
import {AuthService} from '@app/modules/auth/services/auth.service';
import {
  BusinessProfileService
} from "@modules/account-settings/pages/business-profile/services/business-profile.service";
import {MatDialog} from "@angular/material/dialog";
import {BillingAddressService} from "@modules/address/services/billing-address.service";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import {
  addressCardComponentConfig,
  AddressCardComponentConfig,
  AddressCardInput
} from "@shared/component/common/address-card/address-card.component";
import {OrderService} from "@modules/order/services/order.service";
import {Job} from "@modules/job/models/job.model";

@UntilDestroy()
@Component({
  selector: 'app-checkout-page',
  templateUrl: './checkout-page.component.html',
  styleUrls: ['./checkout-page.component.scss'],
})
export class CheckoutPageComponent implements OnInit {
  @Input() country$: Observable<Country>;

  businessProfile$ = this.businessProfileService.activeEntity$;

  activeUser$ = this.authService.activeUser$;
  shouldShowPromoCode = false;

  // billingAddressComponentConfig: BillingAddressComponentConfig = {
  //   ...billingAddressComponentConfig,
  //   showTitle: false,
  //   showDefaultBadged: false,
  //   showActions: true,
  //   showDeleteAction: false,
  //   showManageAction: true,
  //   showEditAction: false,
  //   manageLabel: 'Edit'
  private readonly _addressCardComponentConfig$ = new BehaviorSubject<AddressCardComponentConfig>(null)

  constructor(
    private readonly businessProfileService: BusinessProfileService,
    private readonly dialog: MatDialog,
    private readonly billingAddressService: BillingAddressService,
    private readonly authService: AuthService,
    private readonly orderService: OrderService,
  ) {
  }

  get activeBillingAddress$() {
    return this.billingAddressService.activeEntity$;
  }

  get addressCardComponentConfig$(): Observable<AddressCardComponentConfig> {
    return this._addressCardComponentConfig$.asObservable()
  }

  get selectedBillingAddress$() {
    return this.billingAddressService.selectedBillingAddress$
  }

  ngOnInit(): void {
    this.activeBillingAddress$
      .pipe(
        untilDestroyed(this),
        tap(value => this.setSelectedBillingAddress(value))
      ).subscribe()

    this.orderService
      .activeEntity$
      .pipe(
        untilDestroyed(this),
        tap(order => {
          if (order.orderable instanceof Job) {
            this.shouldShowPromoCode = true
          } else {
            this.shouldShowPromoCode = false
          }

          /**
           * If canUpdateOrderBillingAddress is false do not allow users to update billing address when making payment
           */
          this._addressCardComponentConfig$.next({
            ...addressCardComponentConfig,
            showEditAction: Boolean(order?.canUpdateOrderBillingAddress)
          })

          /**
           * Show order billing address if it has.
           */
          const orderBillingAddress = order?.orderDetail?.address || order?.orderDetail?.billingDetails

          if (orderBillingAddress) {
            this.setSelectedBillingAddress(orderBillingAddress)
          }
        })
      ).subscribe()
  }

  onAddNewAddress() {
    return this.billingAddressService.onAddNewAddress(this.dialog);
  }

  setSelectedBillingAddress(address: AddressCardInput) {
    this.billingAddressService.setSelectedBillingAddress(address)
  }

  changeSelectedAddress(address: AddressCardInput) {
    if (address) {
      this.setSelectedBillingAddress(address)
    }
  }
}
